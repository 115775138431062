import { Suspense, useEffect, useState } from "react";

import Layout from "./layouts/Layout";
import Spinner from "./components/Spinner";
import { bioscopeLogin, handleAuthentication, login } from "./api/keycloalCLient";
import { Stack } from "@mui/material";
import { Button, Paper, styled } from "@material-ui/core";
import Protected from "./components/Protected";

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function App() {
  const [loading, setLoading] = useState(false);
  const[accessToken, setAccessToken] = useState('');

  const handleLogin = async () => {
    setLoading(true);
    await login();
    setLoading(false);
  };

  const handleBioscopeLogin = async () => {
    setLoading(true);
    await bioscopeLogin();
    setLoading(false);
  };

  function getFragmentParams() {
    const hash = window.location.hash.substr(1);
    const result = {};
    hash.split('&').forEach(function(part) {
      const item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  useEffect(() => {
    

  }, []);

  useEffect(() => {
    const fragmentParams = getFragmentParams();
    const code = fragmentParams?.code;
  
    if (code) {
      (async () => {
        setLoading(true);
        const access_token = await handleAuthentication(fragmentParams);
        setAccessToken(access_token);
        setLoading(false);
        
        // Clear URL fragment
        window.history.replaceState(null, null, ' ');
      })();
    }
  }, []);

  function clearSpecificLocalStorageItems() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('oauth_state');
    localStorage.removeItem('oauth_nonce');
    localStorage.removeItem('pkce_code_verifier');
  }
  
  if (loading) {
    return <Spinner />;
  }

  return (
    <Suspense fallback={<Spinner />}>
      <Layout>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'   }}>
        <Protected accessToken={accessToken}/>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh'}}>
        <Div>
          <Stack spacing={2}>
            <Item>
              <Button variant="text" onClick={handleLogin} style={{width: '100%', backgroundColor: '#DA0B2A', color: 'whitesmoke'}}>Bongo Login</Button>
            </Item>
            <Item>
              <Button variant="text" onClick={handleBioscopeLogin} style={{width: '100%', backgroundColor: '#00C388', color: 'whitesmoke'}}>Bioscope Login</Button>
            </Item>
            <Item>
              <Button variant="text" onClick={clearSpecificLocalStorageItems}>Clear Cache</Button>
            </Item>
          </Stack>
        </Div>
        
      </div>
        
      </Layout>
    </Suspense>
  );
}
