// KeycloakClient.js
import { v4 as uuidv4 } from 'uuid';

import config from './../config';
import bioscopeConfig from './../config_for_bioscope';

export const login = async() => {
  const state = generateRandomString(); 
  const nonce = generateRandomString(); 
  const { codeVerifier, codeChallenge } = await generateCodeVerifierAndChallenge();

  // Save state and nonce in localStorage
  localStorage.setItem('oauth_state', state);
  localStorage.setItem('oauth_nonce', nonce);
  localStorage.setItem('pkce_code_verifier', codeVerifier);

  const anonymousUid = state;

  let url;
  if (config.usePkce === true){
    // with PKCE
    url = `${config.authServerUrl}/realms/${config.realm}/protocol/openid-connect/auth?client_id=${config.clientId}&redirect_uri=${config.redirectUri}&state=${state}&response_mode=fragment&response_type=code&scope=openid&nonce=${nonce}&code_challenge=${codeChallenge}&code_challenge_method=S256&kc_locale=${config.locale}&anonymous_id=${anonymousUid}`;
  } else {
    //without PKCE:
    url = `${config.authServerUrl}/realms/${config.realm}/protocol/openid-connect/auth?client_id=${config.clientId}&redirect_uri=${config.redirectUri}&state=${state}&response_mode=fragment&response_type=${config.responseType}&scope=${config.scope}&nonce=${nonce}&kc_locale=${config.locale}&anonymous_id=${anonymousUid}`;
  }
  window.location.href = url;
};

export const bioscopeLogin = async() => {
  const state = generateRandomString(); 
  const nonce = generateRandomString(); 
  const { codeVerifier, codeChallenge } = await generateCodeVerifierAndChallenge();
  const config = bioscopeConfig;

  // Save state and nonce in localStorage
  localStorage.setItem('oauth_state', state);
  localStorage.setItem('oauth_nonce', nonce);
  localStorage.setItem('pkce_code_verifier', codeVerifier);

  let url;
  if (config.usePkce === true){
    // with PKCE
    url = `${config.authServerUrl}/realms/${config.realm}/protocol/openid-connect/auth?client_id=${config.clientId}&redirect_uri=${config.redirectUri}&state=${state}&response_mode=fragment&response_type=code&scope=openid&nonce=${nonce}&code_challenge=${codeChallenge}&code_challenge_method=S256&kc_locale=${config.locale}`;
  } else {
    //without PKCE:
    url = `${config.authServerUrl}/realms/${config.realm}/protocol/openid-connect/auth?client_id=${config.clientId}&redirect_uri=${config.redirectUri}&state=${state}&response_mode=fragment&response_type=${config.responseType}&scope=${config.scope}&nonce=${nonce}&kc_locale=${config.locale}`;
  }
  window.location.href = url;
};

export const handleAuthentication = async (fragmentParams) => {
  //const queryParams = new URLSearchParams(window.location.hash.substring(1));
  const code = fragmentParams?.code;
  console.log("code", code);
  const returnedState = fragmentParams?.state;
  const storedState = localStorage.getItem('oauth_state');

  // Validate state
  if (returnedState !== storedState) {
    throw new Error('Invalid state parameter');
  }

  if (code) {
    return exchangeToken(code);
  }
  // Clear state and nonce from localStorage after validation
  localStorage.removeItem('oauth_state');
  localStorage.removeItem('oauth_nonce');
};

const exchangeToken = async (code) => {
  const params = new URLSearchParams();
  const codeVerifier = localStorage.getItem('pkce_code_verifier');

  params.append('code', code);
  params.append('grant_type', 'authorization_code');
  params.append('client_id', config.clientId);
  params.append('redirect_uri', config.redirectUri);
  params.append('client_secret', config.clientSecret);

  if (config.usePkce === true){
    params.append('code_verifier', codeVerifier);
  }

  const response = await fetch(`${config.authServerUrl}/realms/${config.realm}/protocol/openid-connect/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: params,
  });

  const data = await response.json();
  localStorage.setItem('access_token', data?.access_token);
  localStorage.setItem('refresh_token', data?.refresh_token);
  localStorage.setItem('id_token', data?.id_token);
  return await data?.access_token;
};

function generateRandomString() {
  return uuidv4();
}

async function generateCodeVerifierAndChallenge() {
  const codeVerifier = generateCodeVerifier();
  const buffer = new TextEncoder().encode(codeVerifier);
  const hashed = await crypto.subtle.digest('SHA-256', buffer);
  const codeChallenge = btoa(String.fromCharCode(...new Uint8Array(hashed)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');

  return { codeVerifier, codeChallenge };
}

function generateCodeVerifier() {
  const array = new Uint8Array(32); // 32 bytes will give us 43+ characters after base64 encoding
  window.crypto.getRandomValues(array);
  return btoa(String.fromCharCode.apply(null, array))
    .replace(/\+/g, '-') // Replace '+' with '-'
    .replace(/\//g, '_') // Replace '/' with '_'
    .replace(/=/g, '');  // Remove '='
}