//Config.js
const KEYCLOAK_CONFIG = {
    clientId: 'otplogin',
    clientSecret: 'NS0hijEGkHoBjhr5IAy3I06dWGtlpo4Z',
    redirectUri: 'https://keycloak.dev.bongo-solutions.com',
    realm: 'bongo',
    authServerUrl: 'https://accounts.development.bongobd.com',
    responseType: 'code',
    scope: 'openid',
    usePkce: true,
    locale: 'bn',
  };
  export default KEYCLOAK_CONFIG;
// const KEYCLOAK_CONFIG = {
//     clientId: 'otplogin',
//     clientSecret: 'SzMukLqtfFHoPL5EdDp3Q7wA1kTfoVDQ',
//     redirectUri: 'http://localhost:3000',
//     realm: 'bioscope',
//     authServerUrl: 'https://accounts.dev.bioscopelive.com',
//     responseType: 'code',
//     scope: 'openid',
//     usePkce: true,
//     locale: 'bn',
//   };
//   export default KEYCLOAK_CONFIG;

  
// // dev   https://accounts.dev.bongo-solutions.com/
//   const KEYCLOAK_CONFIG = {
//     clientId: 'otplogin',
//     clientSecret: 'NS0hijEGkHoBjhr5IAy3I06dWGtlpo4Z',
//     redirectUri: 'http://localhost:3000',
//     realm: 'bongo',
//     authServerUrl: 'https://accounts.development.bongobd.com',
//     responseType: 'code',
//     scope: 'openid',
//     usePkce: true,
//     locale: 'en',
//   };
//   export default KEYCLOAK_CONFIG;
