
// bioscope local client
const KEYCLOAK_BIOCOPE_CONFIG = {
    clientId: 'otplogin',
    clientSecret: 'bnQSzLmEaGpPF2TsuJij44Amwb1wDfTX',
    redirectUri: 'https://keycloak.dev.bongo-solutions.com',
    realm: 'bioscope',
    authServerUrl: 'https://accounts.development.bioscopelive.com',
    responseType: 'code',
    scope: 'openid',
    usePkce: true,
    locale: 'bn',
  };
  export default KEYCLOAK_BIOCOPE_CONFIG;



// // bioscope DEV client
// const KEYCLOAK_BIOCOPE_CONFIG = {
//   clientId: 'otplogin',
//   clientSecret: 'P1a5z49F1KCXVLJiMfnc8ZwDdpFj7El7',
//   redirectUri: 'https://keycloak.dev.bongo-solutions.com',
//   realm: 'bioscopelive',
//   authServerUrl: 'https://accounts.dev.bongo-solutions.com',
//   responseType: 'code',
//   scope: 'openid',
//   usePkce: true,
//   locale: 'bn',
// };
// export default KEYCLOAK_BIOCOPE_CONFIG;