import React from "react";
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { makeStyles, styled } from '@material-ui/core/styles';

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // height: '60vh',
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    width: '100%',
  },
  button: {
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
      backgroundColor: '#008000a1',
    },
    marginTop: '20px',
  },
  pre: {
    backgroundColor: '#f5f5f5',
    padding: '10px',
    borderRadius: '5px',
    marginTop: '20px',
    width: '100%',
    overflowX: 'auto',
    fontFamily: 'Courier New, monospace',
    whiteSpace: 'pre-wrap',
  },
  copyButton: {
    marginTop: '10px',
  }
});

const Protected = ({accessToken}) => {
  const classes = useStyles();
  const token = accessToken || localStorage.getItem('access_token');

  const copyToClipboard = () => {
    navigator.clipboard.writeText(token);
  }

  return (
    <>
      {token &&
        <Div>
          <Card className={classes.root}>
            <pre className={classes.pre}>{token}</pre>
            <Button onClick={copyToClipboard} className={classes.copyButton} variant="contained" size="small">
              Copy Token
            </Button>
          </Card>
        </Div>
}
    </>
  );
}

export default Protected;
